
const swipe = require('./swipe')
const debounce = require('./debounce');
swipe(window, document);


if (!Array.from) {
    Array.from = function (object) {
        return [].slice.call(object);
    };
}

class Carousel {
    constructor(containerID) {
        this.containerID = containerID;
        this.container = document.getElementById(this.containerID);
        this.inner = this.container.querySelector('.carousel__container');
        this.prev = this.container.querySelector('.carousel-left');
        this.next = this.container.querySelector('.carousel-right');
        this.images = this.container.querySelectorAll('.carousel__item');
        this.width = Math.round(this.images[0].getBoundingClientRect().width);
        this.carouselControls = this.container.querySelectorAll('.navigation__control');
        this.progressBar = document.querySelector(`#${this.containerID} .navigation__progress-bar:not(.none)`);
        this.currentIndex = 0;
        this.addEventListeners();

    }

    run() {
        this.intrv = window.setInterval(this.switchToRight.bind(this), 6000);
        // this.switchToRight();
        this.isIntrv = true;
    }

    stop() {
        window.clearInterval(this.intrv);
        this.isIntrv = false;
    }

    addEventListeners() {
        if (this.prev) {
            this.prev.addEventListener('click', this.switchToLeft.bind(this));
        }
        if (this.next) {
            this.next.addEventListener('click', this.switchToRight.bind(this, true));
        }

        if (this.carouselControls) {
            this.carouselControls.forEach((button, i) => {
                button.addEventListener('click', () => {
                    this.switchToSlide(i, button);
                });
            });
        }

        window.addEventListener('resize', debounce(() => {
            this.stop();
            this.getWidth();
            this.switchToRight();
        }, 250));

        document.addEventListener('swiped-right', () => {
            this.switchToLeft();
        });
        document.addEventListener('swiped-left', () => {
            this.switchToRight();
        });

    }

    getWidth() {
        this.width = Math.round(this.images[0].getBoundingClientRect().width);
    }

    switchToRight(isClicked) {
        if (isClicked) this.stop();
        this.currentIndex++;
        if (this.currentIndex >= this.images.length) {
            this.currentIndex = 0;
        }
        if (this.progressBar) {
            this.resetProgressBar('right');
        }
        this.switchImg();
    }

    switchToSlide(i, button) {
        this.stop();
        this.currentIndex = i;
        this.resetProgressBar(Number(button.textContent.charAt(1)) - 1);
        this.switchImg();
    }

    switchToLeft() {
        this.stop();
        this.currentIndex--;
        if (this.currentIndex < 0) {
            this.currentIndex = this.images.length - 1;
        }
        if (this.progressBar) {
            this.resetProgressBar('left');
        }
        this.switchImg();
    }

    switchImg() {
        const width = Math.round(this.images[0].getBoundingClientRect().width);
        this.width = width;
        this.inner.style.setProperty('transform', `translateX(${-this.width * this.currentIndex}px)`, '');
        this.inner.style.setProperty('-moz-transform', `translateX(${-this.width * this.currentIndex}px)`, '');
        if (this.carouselControls) {
            this.carouselControls.forEach((b, i) => {
                i === this.currentIndex ? b.classList.add('active') : b.classList.remove('active');
            });
        }
        if (this.progressBar) {
            this.progressBar.classList.add('animate');
            if (this.isIntrv === false) {
                this.run();
            }
        }
    }

    resetProgressBar(direction) {
        this.progressBar = document.querySelector(`#${this.containerID} .navigation__progress-bar:not(.none)`);
        if(this.progressBar) {
            const newBar = this.progressBar.cloneNode(true);
            const parent = this.progressBar.parentNode;
            parent.removeChild(this.progressBar);
            parent.insertBefore(newBar, this.carouselControls[this.currentIndex].nextElementSibling);
        }
    }
}

module.exports = Carousel;
