import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/main.scss'
import ClientsBox from '../components/index-page/clients-box'
import utils from '../js/utils'
import headerLeftImg from '../images/header_left_img.png'
import headerRightImg from '../images/home_header_construction.png'
import solarPanelsImg from '../images/solar_panels.png'
import constructionRoadImg from '../images/construction_road.png'
import trainImg from '../images/train.png'
import AnimateComponent from '../components/animate-component'
import {
  isSafari,
} from 'react-device-detect'


class IndexPage extends Component {

  componentDidMount() {
    this.initCarousels()
  }

  componentWillUnmount() {
    this.unmountCarousels()
  }

  initCarousels = () => {
    if (this.props.location.pathname === '/') {
      const carousel = require('../js/carousel')
      this.clientsCarousel = new carousel('clients')
      this
        .clientsCarousel
        .run()
    }
  }

  unmountCarousels = () => {
    this
      .clientsCarousel
      .stop()
    this.clientsCarousel = null
  }

  render() {
    const posts = this
      .props
      .data
      .wordpressPage
      .acf

      console.log(posts.klienci_repeater);

    let clientsCarouselBoxes2 = utils.chunkArray(posts.klienci_repeater.map((el, i) => {
        console.log(i, el.logo);
        if ( el?.logo?.localFile?.publicURL ) {
          return (<ClientsBox
              key={i}
              logo={el.logo.localFile.publicURL}
              boxClass='carousel__box'
              imgClass='carousel__img' />)
        }
    }), 3);

    const clientsSlidesCount = Math.ceil(posts.klienci_repeater.length / 9)

    let itemList = [];
    if ( clientsSlidesCount > 1 ) {
      for (let i = 2; i <= clientsSlidesCount; i++) {
        itemList.push(<p className='navigation__control' role='button' tabIndex='0'>{String(i).padStart(2, '0')}</p>);
      }
    }

    return (
      <Layout>
        <SEO
          title='Home | Świecki & Puchacz Kancelaria'
          keywords={['kancelaria prawna', 'kancelaria Łódź', 'porady prawne', 'dobry adwokat']} />
        <AnimateComponent>
          <header className='header' id='header'>
            <div className='wow fadeInUp'>
              <div className='header__container'>
                <div className="header__left_box"></div>
                <div className="header__middle_box">
                  <h1 className="header__top_heading">Specjalistyczna obsługa prawna podmiotów publicznych</h1>
                  <div className="header__inner_textbox">
                    <p className="header__paragraph">Jesteśmy kancelarią specjalizującą się w obsłudze prawnej <strong className="header__paragraph--strong">podmiotów publicznych</strong>, co znacząco wyróżnia nas na rynku usług prawniczych. Znamy specyfikę funkcjonowania podmiotów sektora publicznego, a także nałożonych na nie regulacji i obowiązków. Nasza, potwierdzona efektami, skuteczność jest wynikiem połączenia wieloletniego doświadczenia i specjalistycznej wiedzy.</p>
                    <a className="header__link" href="/kontakt/">Zapraszamy do współpracy</a>
                  </div>
                </div>
                <div className="header__right_box">
                  <img
                      src={headerRightImg}
                      className='right__img'
                      alt='zdjęcie budynku Kancelarii' />
                </div>
              </div>
            </div>
          </header>

          <section className="our-offer" id="our-offer">
            <div className='wow fadeInUp'>
              <div className="our-offer__container">
                <div className="our-offer__text-left">
                  <h2 className="our-offer__heading">Nasza oferta</h2>
                  <p>Nasza oferta jest odpowiedzią na potrzeby jednostek sektora finansów publicznych, które wymagają nie tylko kompleksowego wsparcia w procesie zakupowym, ale też zrozumienia specyfiki ich funkcjonowania i pozycji kontraktowej, ograniczonej przepisami o finansach publicznych, dyscypliną finansów, przepisami o zamówieniach publicznych czy systemem działań korygujących przy realizacji projektów UE.</p>
                  <p>Wyróżnia nas doskonała znajomość funkcjonowania podmiotów sektora finansów publicznych oraz przepisów, którym podlegają. Atutem jest także wieloletnia praktyka w prowadzeniu postępowań przetargowych oraz obsłudze sporów kontraktowych. Kancelaria oferuje usługi prawne, obejmujące wsparcie na każdym etapie przebiegu inwestycji publicznej,
                    od momentu jej przygotowania, poprzez postępowanie przetargowe, realizację umowy aż do końcowego rozliczenia.</p>
                  <a className="our-offer__link" href="/oferta/">Czytaj więcej</a>
                </div>
                <div className="our-offer__three-images">
                  <div className="our-offer__top-row">
                    <img
                        src={constructionRoadImg}
                        className='our-offer__top_img'
                        alt='biblioteka' />
                  </div>
                  <div className="our-offer__bottom-row">
                    <div className="leftCol">
                      <img
                          src={solarPanelsImg}
                          className='left_img'
                          alt='city' />
                    </div>
                    <div className="rightCol">
                      <img
                          src={trainImg}
                          className='right_img'
                          alt='ue flag' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='clients' id='clients'>
            <div className='clients__container'>
              <div className='clients__content wow fadeInUp'>
                <h4>{posts.naglowek}</h4>
                <p>{posts.paragraf}</p>
                <p>
                  <Link to={posts.link.url} className="clients__read_more">{posts.link.title}</Link>
                </p>
              </div>
              <div delay='300' className='clients__carousel carousel wow fadeInUp'>
                <div className='carousel__container'>
                  {utils.chunkArray(clientsCarouselBoxes2, 3).map((group, i) => (
                      <div className='carousel__item' key={`outer-column-${i}`}>
                        {group.map((item, j) => (
                            <div className='carousel__col' key={`column-${j}`}>
                              {item}
                            </div>
                        ))}
                      </div>
                  ))}
                </div>
                {}
                <div className='carousel__navigation navigation'>
                  <div className='navigation__controls'>
                    <p className='navigation__control active' role='button' tabIndex='0'>01</p>
                    <div className='navigation__progress-bar animate'></div>
                    {itemList}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='questions' id='questions'>
            <div className='questions__container'>
              <div className='questions__content wow fadeInUp'>
                <h2 className="questions__heading">Masz pytania?</h2>
                <div className="questions__link_container">
                  <a className="questions__link" href="/kontakt/">Skontaktuj się z nami</a>
                </div>
              </div>
            </div>
          </section>
        </AnimateComponent>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
    query {
        imageHome1: file(relativePath: {eq: "images/adam-gabinet-home.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        imageHome2: file(relativePath: {eq: "images/adam-sala-home.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        imageHome3: file(relativePath: {eq: "images/adam-biurko-home.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        imageHome4: file(relativePath: {eq: "images/swiecki_home8.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        allWordpressWpAktualnosci( sort: {fields: date, order: DESC} ) {
            edges {
                node {
                    slug
                    acf {
                        slider_image {
                            localFile {
                                publicURL
                            }
                        }
                        slider_title
                        slider_title_color
                        show_in_slider

                    }

                }

            }
        }

        wordpressPage( slug: {eq: "home"}) {
            id
            title
            content
            excerpt
            date
            modified
            slug
            status
            acf {
              naglowek
              paragraf
              link {
                title
                url
                target
              }
              klienci_repeater {
                logo {
                  localFile {
                    publicURL
                  }
                }
              }
            }
        }


    }
`
